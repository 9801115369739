<template>
  <v-card class="mb-12">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.ROLES.FIELD_DISPLAY_NAME") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ role.name }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.ROLES.RELATION_COUNT_PERMISSIONS") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ permissionsCount }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.ROLES.RELATION_COUNT_USERS") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ role.usersCount }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RoleData",

  props: {
    role: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        name: null,
        displayName: null,
        level: null,
        isSelectable: null,
        permissions: [],
        users: [],
        usersCount: null,
      }),
    },
  },

  computed: {
    permissionsCount() {
      return this.role.permissions.length;
    },
  },
};
</script>
