<template>
  <v-card flat>
    <v-card-title>
      <BaseTextField
        v-model="search"
        label="USER_MANAGEMENT.ROLES.PERMISSIONS_TABLE_SEARCH"
        append-icon="far fa-search"
        hide-details
      />
    </v-card-title>
    <v-card-text class="py-2">
      <v-data-table :items="permissions" :headers="headers" :search="search" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RolePermissionTable",
  props: {
    permissions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("USER_MANAGEMENT.ROLES.DETAILS_PERMISSIONS_TABLE_HEADERS_DISPLAY_NAME"),
          value: "displayName",
        },
        {
          text: this.$t("USER_MANAGEMENT.ROLES.DETAILS_PERMISSIONS_TABLE_HEADERS_DESCRIPTION"),
          value: "description",
        },
      ];
    },
  },

  data() {
    return {
      search: null,
    };
  },
};
</script>
