<template>
  <ModuleSubPage fullWidth :loading="isLoading" v-if="!!role">
    <BaseHasPermission :permissionNames="['role.read']">
      <v-row>
        <v-col>
          <BaseBackButton />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BaseHeadline size="3">
            {{ $t("USER_MANAGEMENT.ROLES.DETAIL_PAGE_TITLE") }}: {{ role.displayName }}
          </BaseHeadline>
        </v-col>
      </v-row>

      <RoleData :role="role" />

      <v-divider />

      <v-expansion-panels>
        <v-expansion-panel v-if="!!role.permission">
          <v-expansion-panel-header>
            {{ $t("USER_MANAGEMENT.ROLES.MGMNT_DETAILS_ROLE_ASSIGN_PERMISSION_TITLE") }} ({{
              role.permissions.length
            }})
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pa-0">
            <RolePermissionTable :permissions="role.permissions" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="'users' in role">
          <v-expansion-panel-header>
            {{ $t("USER_MANAGEMENT.ROLES.MGMNT_DETAILS_ROLE_ASSIGN_USER_TITLE") }} ({{
              role.usersCount
            }})
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pa-0">
            <RoleUserTable :users="role.users" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import RoleData from "@views/Restricted/UserManagement/Role/Show/partials/RoleData.vue";
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import RoleUserTable from "@views/Restricted/UserManagement/Role/Show/partials/RoleUsersTable.vue";
import RolePermissionTable from "@views/Restricted/UserManagement/Role/Show/partials/RolePermissionsTable.vue";

export default {
  name: "RoleDetail",

  components: {
    RolePermissionTable,
    RoleUserTable,
    ModuleSubPage,
    RoleData,
  },

  data() {
    return {
      isLoading: true,
      role: null,
    };
  },

  computed: {
    roleId() {
      return parseInt(this.$route.params.roleId, 10) || null;
    },
  },

  methods: {
    async retrieveRole() {
      if (!this.roleId) {
        return;
      }
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.showRole({ roleId: this.roleId });
        this.role = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.retrieveRole();
  },
};
</script>
